import React, { FC } from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FieldProps,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import {
  ShowActionsToolbar,
  CreateActionsToolbar,
  EditActionsToolbar
} from "../common";

import {available_device_versions} from "../core/constants";

export interface Device {
  id: string;
  device_id: string;
  device_version: number;
  firmware_version: string;
  hardware_version: string;
  created: Date;
  modified: Date;
}

interface DeviceProps extends FieldProps {
  source?: keyof Device;
  record?: Device;
}

const DeviceTitle: FC<DeviceProps> = ({ record }) => (
  <span>Faq {record ? `"${record.device_id}"` : ""}</span>
);


const DeviceList: FC = (props) => (
  <List
    {...props}
    sort={{ field: "created", order: "desc" }}
    // actions={<ListActions />}
  >
    <Datagrid rowClick="show">
      <TextField source="device_id" />
      <NumberField source="device_version" />
      <TextField source="firmware_version" />
      <TextField source="hardware_version" />
      <ReferenceField source="last_used_by" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <DateField source="created" showTime />
      <DateField source="modified" showTime />
      <EditButton />
    </Datagrid>
  </List>
);

const DeviceShow: FC = (props) => (
  <Show actions={<ShowActionsToolbar />} title={<DeviceTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="Id" source="id" />
      <TextField label="Device ID" source="device_id" />
      <NumberField label="Device version" source="device_version" />
      <TextField label="Firmware version" source="firmware_version" />
      <TextField label="Hardware version" source="hardware_version" />
      <DateField source="created" showTime />
      <DateField source="modified" showTime />
    </SimpleShowLayout>
  </Show>
);

const DeviceCreate: FC = (props) => (
  <Create actions={<CreateActionsToolbar />} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Device ID" source="device_id" validate={required()} />
      <SelectInput
          source="device_version"
          choices={available_device_versions}
          defaultValue={1}
          validate={required()}
      />
      <TextInput source="firmware_version" validate={required()} />
      <TextInput source="hardware_version" validate={required()} />
      <ReferenceInput source="last_used_by" reference="users" validate={required()}>
        <SelectInput optionText="email" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const DeviceEdit: FC = (props) => {
  return (
    <Edit
      actions={<EditActionsToolbar />}
      title={<DeviceTitle />}
      mutationMode="pessimistic"
      {...props}
    >
      <SimpleForm redirect="list">
        <TextInput disabled source="id" />
        <TextInput disabled label="Device ID" source="device_id" validate={required()} />
        <SelectInput
            source="device_version"
            choices={available_device_versions}
            defaultValue={1}
            validate={required()}
        />
        <TextInput source="firmware_version" validate={required()} />
        <TextInput source="hardware_version" validate={required()} />
        <ReferenceInput source="last_used_by" reference="users" validate={required()}>
          <SelectInput optionText="email" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default {
  list: DeviceList,
  create: DeviceCreate,
  edit: DeviceEdit,
  show: DeviceShow
};
