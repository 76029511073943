import * as Sentry from "@sentry/react";
import {
  CreateParams,
  DataProvider,
  fetchUtils,
  GetOneParams,
  HttpError,
  UpdateManyParams,
  UpdateManyResult
} from "react-admin";
import sample from "lodash/sample";

const queryString = require("query-string");
// DEV ONLY! Used for testing on both local and remote (Virtual) machine
// which accesses the admin panel & API from a different IP address
// const apiUrl = window.location.origin.replace("3001", "3000") + "/api/v1";
const apiUrl = process.env.REACT_APP_API_HOST + "/api/v1";

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  const token = localStorage.getItem("token");
  options.user = {
    authenticated:
      JSON.parse(localStorage.getItem("auth") || "{}")._id !== undefined,
    token: `Bearer ${token}`
  };

  return fetchUtils.fetchJson(url, options).catch(({ status, body }) => {
    if (status === 401) {
      body.error = "Authorization Expired. Please sign in again";
    }
    const errorMsg =
      (body?.errors ? sample(body?.errors)[0] : body?.error) ||
      "Unknown server error";
    if (status >= 500) {
      Sentry.captureException(errorMsg, {
        extra: { status, body }
      });
    }
    throw new HttpError(errorMsg, status, body);
  });
};

const getTotalFromHeaders = (headers: Headers): number => {
  const range = headers.get("Content-Range");
  let total = "0";
  if (range) {
    total = range.split("/").pop() || total;
  }

  return parseInt(total);
};

const convertFileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.total < 10) {
        return reject("File is too small. Should be bigger than 10B");
      }
      if (event.total > 10000000) {
        return reject("File is too big. Should be less than 10MB");
      }

      return resolve(
        (typeof reader.result == "string" &&
          reader.result.replace(/(^data:.+;base64,)/g, "")) ||
          ""
      );
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

type DataProviderAdvanced = DataProvider & {
  createMany: (resource: string, params: UpdateManyParams) => Promise<any>;
  updateManyArray: (
    resource: string,
    params: UpdateManyParams
  ) => Promise<UpdateManyResult>;
  sendTopicMessage: (resource: string,  params: CreateParams & GetOneParams, resourceId: string,) => Promise<any>;
};

const dataProvider: DataProviderAdvanced = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    // Change `q => name` because backend doesn't know how to handle `q` queries
    if (params.filter?.q) {
      delete Object.assign(params.filter, { ["name"]: params.filter["q"] })[
        "q"
      ];
    }
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter)
    };

    const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
    const { headers, json } = await httpClient(url);
    return {
      data: json.data.map((entry: Record<string, any>) => {
        if (entry.packages && typeof entry.packages == "object") {
          entry.packages = entry.packages.reduce(
            (result: Array<string>, el: any) => {
              result.push(el.package);
              return result;
            },
            []
          );
        }
        const oldId = entry.id;
        delete entry.id;
        return {
          id: entry._id,
          oldId,
          ...entry
        };
      }),
      total: getTotalFromHeaders(headers)
    };
  },

  getOne: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      delete json.data.id;
      if (json.data.packages && typeof json.data.packages == "object") {
        json.data.packages = json.data.packages.reduce(
          (result: Array<string>, el: Record<string, any>) => {
            result.push(el.package);
            return result;
          },
          []
        );
      }
      return {
        data: { id: json.data._id, ...json.data }
      };
    });
  },

  getMany: async (resource, params) => {
    // Hack for removing nested empty arrays, etc
    const ids = params.ids.filter((el: any) => el.length > 0);
    const query = {
      filter: JSON.stringify({ id: ids })
    };
    const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

    const { json } = await httpClient(url);
    return {
      data: json.data.map((entry: Record<string, any>) => {
        if (entry.packages && typeof entry.packages == "object") {
          entry.packages = entry.packages.reduce(
            (result: Array<string>, el: any) => {
              result.push(el.package);
              return result;
            },
            []
          );
        }
        const oldId = entry.id;
        delete entry.id;
        return {
          id: entry._id,
          oldId,
          ...entry
        };
      })
    };
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id
      })
    };
    const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

    const { headers, json } = await httpClient(url);
    return {
      data: json.data.map((entry: Record<string, any>) => {
        if (entry.packages && typeof entry.packages == "object") {
          entry.packages = entry.packages.reduce(
            (result: Array<string>, el: any) => {
              result.push(el.package);
              return result;
            },
            []
          );
        }
        const oldId = entry.id;
        delete entry.id;
        return {
          id: entry._id,
          oldId,
          ...entry
        };
      }),
      total: getTotalFromHeaders(headers)
    };
  },

  update: async (resource, params) => {
    delete params.data.id;
    delete params.data._id;
    delete params.data.created;
    delete params.data.modified;
    delete params.data.__v;
    delete params.data.oldId;

    if (params.data.packages?.length) {
      try {
        params.data.packages = params.data.packages.reduce(
          (result: { package: string }[], id: string) => {
            result.push({ package: id });
            return result;
          },
          []
        );
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    if (resource === "workouts") {
      if (!params.data.filename && !params.data.csvFile) {
        throw new Error("Missing or Incorrect CSV File");
      }
      if (params.data.csvFile) {
        const extension = params.data.csvFile.title.split(".").pop();
        if (extension != "csv") {
          throw new Error("File should be in CSV format");
        }
        params.data.filename = params.data.csvFile.title;
        params.data.contents = await convertFileToBase64(
          params.data.csvFile.rawFile
        );

        delete params.data.csvFile;
      }
    } else if (resource === "firmware") {
      if (!params.data.filename && !params.data.firmwareFile) {
        throw new Error("Missing or Incorrect firmware File");
      }
      if (params.data.firmwareFile) {
        const extension = params.data.firmwareFile.title.split(".").pop();
        if (params.data.type != extension) {
          throw new Error(`File should be in "${params.data.type}" format`);
        }
        params.data.filename = params.data.firmwareFile.title;
        params.data.contents = await convertFileToBase64(
          params.data.firmwareFile.rawFile
        );

        delete params.data.firmwareFile;
      }
    }

    if (resource === "users") {
      delete params.data.type;
      delete params.data.upgraded;
      delete params.data.promocode;
      delete params.data.email;
      delete params.data.premium;
    }

    const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PATCH",
      body: JSON.stringify(params.data)
    });

    const oldId = json.data.id;
    delete json.data.id;

    return {
      data: { id: json.data._id, oldId, ...json.data }
    };
  },

  updateMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    const { json } = await httpClient(
      `${apiUrl}/${resource}?${queryString.stringify(query)}`,
      {
        method: "PATCH",
        body: JSON.stringify(params.data)
      }
    );
    return {
      data: json.data.map((entry: Record<string, unknown>) => {
        delete entry.id;
        return {
          id: entry._id,
          ...entry
        };
      })
    };
  },
  // For csv import
  updateManyArray: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    const { json } = await httpClient(
      `${apiUrl}/${resource}?${queryString.stringify(query)}`,
      {
        method: "PATCH",
        body: JSON.stringify(params.data)
      }
    );
    return {
      data: json.data.map((entry: Record<string, unknown>) => {
        delete entry.id;
        return {
          id: entry._id,
          ...entry
        };
      })
    };
  },

  create: async (resource, params) => {
    if (params.data.packages?.length) {
      try {
        params.data.packages = params.data.packages.reduce(
          (result: { package: string }[], id: string) => {
            result.push({ package: id });
            return result;
          },
          []
        );
      } catch (error) {
        Sentry.captureException(error);
      }
    }
    if (resource === "workouts") {
      if (!params.data.filename && !params.data.csvFile) {
        throw new Error("Missing or Incorrect CSV File");
      }
      if (params.data.csvFile) {
        const extension = params.data.csvFile.title.split(".").pop();
        if (extension != "csv") {
          throw new Error("File should be in CSV format");
        }
        params.data.filename = params.data.csvFile.title;
        params.data.contents = await convertFileToBase64(
          params.data.csvFile.rawFile
        );

        delete params.data.csvFile;
      }
    } else if (resource === "firmware") {
      if (!params.data.filename && !params.data.firmwareFile) {
        throw new Error("Missing or Incorrect firmware File");
      }
      if (params.data.firmwareFile) {
        const extension = params.data.firmwareFile.title.split(".").pop();
        if (params.data.type != extension) {
          throw new Error(`File should be in "${params.data.type}" format`);
        }
        params.data.filename = params.data.firmwareFile.title;
        params.data.contents = await convertFileToBase64(
          params.data.firmwareFile.rawFile
        );

        delete params.data.firmwareFile;
      }
    }

    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data)
    }).then(({ json }) => {
      delete json.data.id;
      return {
        data: { id: json.data._id, ...json.data }
      };
    });
  },
  // For csv import
  createMany: async (resource, params) => {
    const { json } = await httpClient(`${apiUrl}/${resource}`, {
      method: "PATCH",
      body: JSON.stringify(params.data)
    });
    return {
      data: json.data.map((entry: Record<string, unknown>) => {
        delete entry.id;
        return {
          id: entry._id,
          ...entry
        };
      })
    };
  },

  delete: (resource, params): Promise<any> => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE"
    }).then(() => {
      return { data: { id: params.id } };
    });
  },

  deleteMany: async (resource, params) => {
    const { json } = await httpClient(
      `${apiUrl}/${resource}/${params.ids.join(",")}`,
      {
        method: "DELETE"
      }
    );

    return {
      data: json?.data || []
    };
  },

  sendTopicMessage: async (resource: string, params: CreateParams & GetOneParams) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}/send-topic-message`, {
      method: "POST",
      body: JSON.stringify(params.data)
    }).then(({ json }) => {
      delete json.data.id;
      return {
        data: { id: json.data._id, ...json.data }
      };
    });
  },
};

export default dataProvider;
