import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  required,
  EditButton,
  Show,
  PasswordInput,
  EmailField,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  ReferenceArrayField,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  maxLength,
  minLength,
  CreateProps,
  regex,
  Labeled
} from "react-admin";
import {
  BGBooleanField,
  CreateActionsToolbar,
  EditActionsToolbar,
  ShowActionsToolbar
} from "../common";
import { validateEmail, validatePassword } from "../validators";
import { FilterSidebar, FilterTop } from "./filters";
import { User } from "./types";
import {TopicMessageForm} from "../core/components";

interface UserProps {
  record?: User;
}

const UserTitle: React.FC<UserProps> = ({ record }) => {
  return <span>User {record ? `"${record.name} <${record.email}>"` : ""}</span>;
};

const UserList: React.FC = (props) => (
  <List
    aside={<FilterSidebar />}
    filters={<FilterTop />}
    bulkActionButtons={false}
    sort={{ field: "last_login", order: "DESC" }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="name" label="Full name" />
      <EmailField source="email" />
      <TextField source="oldId" label="Old ID" />
      <ReferenceArrayField reference="packages" source="packages">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      {/* <ReferenceArrayField reference="promocodes" source="promocodes">
        <SingleFieldList>
          <ChipField source="code" />
        </SingleFieldList>
      </ReferenceArrayField> */}
      <BGBooleanField source="enabled" sortable={false} />
      {/* <FunctionField
        label="Migrated"
        render={(record: any) =>
          record && (record.packages?.length > 0 || record.pin != "0000") ? (
            <TrueIcon />
          ) : (
            <FalseIcon />
          )
        }
      /> */}
      <BGBooleanField source="is_admin" label="Admin" />
      <DateField source="created" label="Date Created" showTime />
      <DateField source="last_login" label="Last logged in" showTime />
      <EditButton />
    </Datagrid>
  </List>
);

// const PromocodesField: React.FC<UserProps> = ({ record }) => {
//   return (
//     <>
//       {record && record.promocodes.length ? (
//         record.promocodes.map((item) => <span>{item}</span>)
//       ) : (
//         <>None</>
//       )}
//     </>
//   );
// };

const UserShow: React.FC = (props) => (
  <Show actions={<ShowActionsToolbar />} title={<UserTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="name" label="Full name" />
        <EmailField source="email" />
        <TextField source="phone" />
        <ReferenceArrayField
          label="Promocodes"
          reference="promocodes"
          source="promocodes"
        >
          <SingleFieldList>
            <ChipField source="code" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          label="Packages"
          reference="packages"
          source="packages"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TopicMessageForm {...props} />
      </Tab>
      <Tab label="security" path="security">
        <TextField source="pin" />
        <Labeled label="Enabled">
          <BGBooleanField source="enabled" />
        </Labeled>
        <Labeled label="Is Admin">
          <BGBooleanField source="is_admin" />
        </Labeled>
        <Labeled label="Firmware upgraded">
          <BGBooleanField source="firmware.upgraded" />
        </Labeled>
        <TextField source="firmware.target_id" />
      </Tab>
      <Tab label="logs" path="logs">
        <DateField label="Date Created" source="created" showTime />
        <DateField label="Date Modified" source="modified" showTime />
        <DateField label="Last logged in" source="last_login" showTime />
        <TextField label="Last login IP" source="last_login_ip" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const validateName = [
  required(),
  minLength(4),
  maxLength(50),
  regex(
    /^[\w0-9-_ ąęćźżłóśń]+$/,
    "Name must not contain any special characters"
  )
];

const UserCreate: React.FC<CreateProps> = (props) => (
  <Create actions={<CreateActionsToolbar />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" label="Full name" validate={validateName} />
      <TextInput
        source="email"
        validate={validateEmail}
        type="email"
        autoComplete="false"
      />
      <PasswordInput
        source="password"
        autoComplete="false"
        validate={validatePassword}
      />
      <TextInput source="phone" />
      <ReferenceArrayInput source="packages" reference="packages" allowEmpty>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <BooleanInput source="enabled" defaultValue={true} />
      <BooleanInput source="is_admin" defaultValue={false} />
    </SimpleForm>
  </Create>
);

const UserEdit: React.FC = (props) => (
  <Edit
    actions={<EditActionsToolbar />}
    title={<UserTitle />}
    mutationMode="pessimistic"
    {...props}
  >
    <SimpleForm redirect="list">
      <TextInput disabled label="Id" source="_id" />
      <TextInput disabled source="email" />
      <TextInput source="name" label="Full name" validate={validateName} />
      <TextInput source="phone" />
      <ReferenceArrayInput source="packages" reference="packages" allowEmpty>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <BooleanInput source="enabled" />
      <BooleanInput source="is_admin" />
    </SimpleForm>
  </Edit>
);

export default {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  show: UserShow
};
