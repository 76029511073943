import React, {PropsWithChildren, useState} from "react";
import {BooleanInput, Button, DateInput, Edit, required, SimpleForm, TextInput, useMutation} from "react-admin";

export const TopicMessageForm = (props: PropsWithChildren<Record<string, unknown>>) => {
  const isUsersResource = props.resource === "users"
  const [useCustomMessage, setUseCustomMessage] = useState(isUsersResource)
  const [message, setMessage] = useState("")
  const [lastSession, setLastSession] = useState("")
  const [approve, {loading}] = useMutation({
    type: 'sendTopicMessage',
    resource: props.resource as string,
    payload: {
      id: props.id,
      data: {message: message, use_custom_message: useCustomMessage, last_session_at: lastSession}
    },
  });
  const disableTextInput = () => {
    if (isUsersResource) {
      return false
    }
    return useCustomMessage === isUsersResource
  }
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<></>}>
        {!isUsersResource &&
          <>
            <DateInput defaultValue="" source="lastSessionAt" label="Last session since" onChange={(e: any) => {
              setLastSession(e.target.value);
            }}/>
            <BooleanInput defaultValue={useCustomMessage} label="Use custom message" source="useCustomMessage"
                          onChange={() => {
                            setUseCustomMessage(!useCustomMessage);
                          }}/>
          </>
        }
        <TextInput disabled={disableTextInput()} source="message" validate={required()} onChange={(e: any) => {
          setMessage(e.target.value);
        }}>
        </TextInput>
        <Button label="Send topic message" onClick={approve} disabled={loading}/>
      </SimpleForm>
    </Edit>
  );
}